import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/@nextui-org/card/dist/index.mjs");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/node_modules/next/font/google/target.css?{\"path\":\"src/app/(home)/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/Adsver/CatfishBottom.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/Adsver/Popup_desktop.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/Footer.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/Header.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/Navbar.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/components/SideBar/TabSideBar.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/globals.css");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/AccountProvider.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/ClickProvider.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/NextUiProvider.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/ReactQueryProvider.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/SnackbarProvider.jsx");
import(/* webpackMode: "eager" */ "/var/www/cliphott69.ecommflex.store/src/providers/WatchMovieMode.jsx")
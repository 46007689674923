"use client"
import { usePathname } from "next/navigation";
import React, { useReducer, createContext, useMemo, useContext, useEffect } from "react";

export const ClickContext = createContext({
    dataLinkClick: [],
    setDataLinkClick: () => { },
});

const reducer = (state, action) => {
    switch (action.type) {
        case "COUNT_DOWN": {
            const updatedState = [...state];
            updatedState.forEach((item, index) => {
                if (index === action.payload.index) {
                    item.ads[0].duration = action.payload.duration;
                }
            });
            return updatedState;
        }
        default: {
            return state;
        }
    }
};

export const useClickContext = () => useContext(ClickContext);

export default function ClickProvider({ adsData, children }) {
    const [dataLinkClick, setDataLinkClick] = useReducer(reducer, adsData);
    const contextValue = useMemo(
        () => ({
            dataLinkClick,
            setDataLinkClick,
        }),
        [dataLinkClick, setDataLinkClick]
    );
    const pathname = usePathname();
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * dataLinkClick.length);
        const handleLinkClick = () => {
            if (dataLinkClick[randomIndex]?.ads.length > 0) {
                const ad = dataLinkClick[randomIndex]?.ads[0];
                if (ad && ad.duration > 0) {
                    window.open(ad.url, "_blank", "noopener,noreferrer");
                    setDataLinkClick({
                        type: "COUNT_DOWN",
                        payload: {
                            index: randomIndex,
                            duration: ad.duration - 1,
                        },
                    });
                }
            }
        };

        handleLinkClick();
    }, [pathname]);
    return (
        <ClickContext.Provider value={contextValue}>
            {children}
        </ClickContext.Provider>
    )
}
"use client"
import { Button } from '@nextui-org/button';
import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/modal';
import Link from 'next/link';
import React, { useEffect, useState } from 'react'
import {AiOutlineClose} from "react-icons/ai";
import {Image} from "@nextui-org/image";
import { usePathname } from 'next/navigation';

export default function Popup_desktop({data}) {
    const [maximumShowAd,setMaximumShowAd] = useState(0);
    if(data.length === 0) return <></>;
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const pathName = usePathname();

    useEffect(() => {
        onOpenChange();
    }, []);
    const onUpdateMaxShowAd = () => {
        setMaximumShowAd(maximumShowAd + 1);
        if(maximumShowAd == 0){
            const randomIndex = Math.floor(Math.random() * data.length);
            const {url} = data[randomIndex];
            window.open(url, "_blank", "noopener,noreferrer");
        }
    }
    useEffect(() => {
        onOpenChange();
    }, [pathName]);
    return (
            maximumShowAd < 3 && 
            (
                <Modal 
                    aria-labelledby='Popup Enter Email To Receive Notifications When New Products Are Available'
                    backdrop="opaque" 
                    size="md"
                    isOpen={isOpen} 
                    placement="auto" 
                    onOpenChange={onOpenChange}
                    // onClose={onUpdateMaxShowAd}
                    classNames={{
                        backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20 z-[70]",
                        wrapper: "z-[71]",
                        closeButton:"z-10 cursor-pointer hover:"
                    }}
                    closeButton={
                        <Button color='danger' variant='shadow' isIconOnly><AiOutlineClose size={18}/></Button>
                    }
                >
                    <ModalContent>
                        <ModalBody>
                            <Link href={data[0].url} prefetch={false} target='_blank'>
                                <div className='mt-10 flex items-center justify-center'>
                                    <Image src={data[0]?.image} alt="Quảng Cáo"/>
                                </div>
                            </Link>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )
    )
}
